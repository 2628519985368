<template>
  <div>
    <header>
      <van-nav-bar :title="$t('withdrawal.title')" class="bg-b-2 text-t-1">
        <template #left>
          <van-icon name="arrow-left" size="20" @click="JumpGo" />
        </template>
      </van-nav-bar>
    </header>

    <div class="p-3">
      <div class="main" v-if="UserInfo">
        <div class="mb-5 p-3 flex flex-col justify-between rounded-md bg-b-2 shadow">
          <div class="flex flex-col justify-between mb-3">
            <div class="text-sm">{{ $t("withdrawal.yue") }}</div>
            <div class="text-3xl">
              <span class="text-xl">{{ monetary_unit }} {{ $t("Mex") }}</span><span class="">{{ UserInfo.money }}</span>
            </div>
          </div>
          <div class="text-xs font-light">
            <div>{{ $t("withdrawal.shouxufei") }}:0%</div>
            <div>{{ $t("withdrawal.dancitixian") }}:0</div>
          </div>
        </div>

        <!-- tixian -->
        <!-- <div class="contant">
          <div class="title">{{ $t("withdrawal.shuru") }}</div>
          <div>
            <div class="leftTxt">{{ $t("Mex") }}{{ monetary_unit }}</div>
            <div class="flex justify-between items-center py-2">
              <input class="flex-1  p-1  mr-2 border-b border-b-t-2 text-xl" type="number" v-model="WithdrawalMoney" />
              <div class="text-xs bg-p-1 text-a-2 text-center px-2 py-1 rounded-md"
                @click="WithdrawalMoney = parseInt(UserInfo.money)">
                {{ $t("withdrawal.suoyou") }}
              </div>
            </div>
          </div>
          <div class="tip">
            <div class="T-left-txt">
              {{ $t("withdrawal.dangqianjine") }}：{{ WithdrawalMoney }}
            </div>

          </div>
        </div> -->

        <!-- bankinfo -->
        <div class="bank-space-y-2 mb-10">
          <div>
            <div class="text-sm">{{ $t("withdrawal.shuru") }}</div>
            <div class="flex justify-between items-center py-2">
              <input class="flex-1  p-1  mr-2 border-b border-b-t-2 text-xl" type="tel" v-model="WithdrawalMoney">
              <div class="text-xs bg-p-1 text-a-2 text-center px-2 py-1 rounded-md"
                @click="WithdrawalMoney = parseInt(UserInfo.money)">
                {{ $t("withdrawal.suoyou") }}</div>
            </div>
            <!-- <p class="text-sm font-light"> {{ $t("withdrawal.dangqianjine") }}：{{ WithdrawalMoney }}</p> -->
          </div>
          <template v-if="typelang == 'pe'">
            <div class="input flex-row" v-for="(item, index) in cardList.slice(0, 3)" :key="index">
              <div class="flex-left-text">{{ item.font }}</div>
              <div class="card-number bank-num" v-if="List">
                {{ List.custom[item.property] }}
              </div>
            </div>
          </template>
          <template v-else>
            <div class="flex flex-col  divide-y text-sm">
              <div class="flex items-center justify-between py-3">
                <div class="flex-left-text">{{ $t("address.xm") }}</div>
                <div class="card-number bank-num" v-if="List">
                  {{ List.name }}
                </div>
              </div>
              <div class="flex items-center justify-between py-3">
                <div class="flex-left-text">{{ $t("address.dianhua") }}</div>
                <div class="card-number bank-num" v-if="List">
                  {{ List.mobile }}
                </div>
              </div>
              <div class="flex items-center justify-between py-3">
                <div class="flex-left-text">{{ $t("prsonal.yhk") }}</div>
                <div class="card-number bank-num" v-if="List">
                  {{ nameBank }}
                </div>
              </div>
            </div>

          </template>

        </div>
        <!-- 通知 -->
        <div class="notice">
          <div class="Ntitle">{{ $t("withdrawal.tps") }}:</div>
          <div class="Ncontant">
            {{ $t("withdrawal.text") }}
          </div>
          <!-- <div class="Ncontant">
          A las 2:24 en punto antes del día siguiente, la hora específica de
          llegada está sujeta al banco
        </div> -->
        </div>
        <!-- 按钮 -->
        <div class="flex">
          <div class="w-full py-3 text-center bg-p-1 rounded-md text-p-3" @click="ShowPassCode = true">{{
            $t("withdrawal.queren") }}</div>
        </div>
      </div>
      <tabbar />
      <!-- 弹出框 -->

      <van-dialog v-model:show="ShowInvitation" :title="$t('alert.title')" show-cancel-button
        :confirmButtonText="$t('alert.qianwang')" :cancelButtonText="$t('alert.quxiao')" cancelButtonColor="#000000"
        confirmButtonColor="#007aff" @confirm="confirmShare">
        <div class="ShowInvitationDiv">{{ $t("withdrawal.guanlian") }}</div>
      </van-dialog>


      <!-- 二级密码遮罩层 -->
      <van-popup v-model:show="ShowPassCode" position="bottom" :style="{ height: '22rem' }" round>
        <div class="flex flex-col">
          <div class="flex flex-col space-y-1">
            <div class="py-2 text-center">{{ $t("Detalles.shuruzhifu") }}</div>
            <div class="items-center">
              <van-password-input :gutter="10" :value="passCode" :focused="showKeyboard" />
            </div>
          </div>
          <div class="pwd-forget"></div>
          <div class="digital-keyboard digital-keyboard-show">
            <van-number-keyboard v-model="passCode" :extra-key="[]" :show="showKeyboard" />
          </div>
        </div>
      </van-popup>
    </div>

  </div>
</template>

<script>
// @ is an alias to /src
import userApi from "@/api/user";
import Tabbar from "@/components/tabbar/tabbar.vue";
export default {
  name: "Withdrawal",
  components: { Tabbar },
  data() {
    return {
      UserInfo: null,
      List: null,
      WithdrawalMoney: "0.00",
      ShowInvitation: false,
      passCode: "",
      ShowPassCode: false,
      showKeyboard: true,
      cardList: "",
      nameBank: "",
      typelang: "",
      bgColor: ''
    };
  },
  computed: {
    monetary_unit() {
      console.log(this.$store.state.monetary_unit);
      return this.$store.state.monetary_unit;
    },
  },
  watch: {
    passCode(value) {
      let that = this;
      if (value.length === 6) {
        this.$toast.loading({
          message: this.$t("Detalles.yanzheng"),
          forbidClick: true,
          duration: 1000,
          onClose() {
            // that.ShowPassCode = false;
            that.save();
          },
        });
      }
    },
  },
  methods: {
    JumpGo() {
      this.$router.go(-1);
    },
    userinfo() {
      userApi
        .userInfo()
        .then((data) => {
          this.UserInfo = data.data;
          console.log(data.data.headimgurl, "个人信息");
          this.$toast.clear();
        })
        .catch((err) => {
          this.$toast.clear();
          this.$toast.fail(err.message);
        });
    },
    bankList() {
      userApi
        .bank()
        .then((data) => {
          this.List = data.data;
          let customKeyArr = data.data.custom;
          if (this.typelang != "zh") {
            this.bankListText();
          }
          // 巴西语言外默认都用中文的方式-判断也分两种
          if (this.typelang != "pe") {
            if (data.data.bank_id == 0 || !data.data.account || !data.data.name) {
              this.ShowInvitation = true;
              return;
            }
          } else {
            for (let i = 0; i < this.cardList.length; i++) {
              if (this.cardList[i].required == 1) {
                console.log(this.cardList[i].property, "findValue");
                if (!customKeyArr[this.cardList[i].property]) {
                  this.ShowInvitation = true;
                  return;
                }
              }
            }
          }


          // let custom = Object.values(data.data.custom);
          // console.log(custom);
          // if (!custom) {
          //   // 为绑定银行卡
          // }
          // for (let i = 0; i < custom.length; i++) {
          //   if (!custom[i]) {
          //     this.ShowInvitation = true;
          //     break;
          //   }
          // }

          this.$toast.clear();
        })
        .catch((err) => {
          this.$toast.clear();
          this.$toast.fail(err.message);
        });
    },
    bankListText() {
      userApi
        .bankList()
        .then((data) => {
          console.log(this.List, "this.List");
          let nameBank = data.data.find((item) => item.id == this.List.bank_id);
          if (nameBank) {
            this.nameBank = nameBank.name;
          }
        })
        .catch((err) => {
          this.$toast.clear();
          this.$toast.fail(err.message);
        });
    },
    //提现
    save() {
      let mark =
        this.List.name +
        " " +
        this.List.account +
        " " +
        this.List.mobile +
        " " +
        this.List.bank_name;
      console.log(mark);

      userApi
        .withdraw(this.WithdrawalMoney, mark, this.passCode)
        .then((data) => {
          console.log(data.data);
          this.$toast.clear();
          this.$dialog
            .alert({
              title: this.$t("alert.title"),
              confirmButtonText: this.$t("alert.queding"),
              confirmButtonColor: "#007aff",
              message: data.message,
            })
            .then(() => {
              // on close
            });
        })
        .catch((err) => {
          this.$toast.clear();
          this.$dialog
            .alert({
              title: this.$t("alert.title"),
              confirmButtonText: this.$t("alert.queding"),
              confirmButtonColor: "#007aff",
              message: err.message,
            })
            .then(() => {
              // on close
            });
        });
    },
    confirmShare() {
      this.$router.push({
        path: "/card",
      });
    },
  },
  created() {
    this.bgColor = localStorage.getItem("bgColor");
    // 进入页面判断是否绑定过银行卡
    this.$toast.loading({
      message: this.$t("Conectando"),
      forbidClick: true,
      duration: 0,
    });
    this.userinfo();
    this.bankList();
    this.cardList = JSON.parse(localStorage.getItem("nation_elect"));
    this.typelang = localStorage.getItem("locale");
    console.log(this.cardList);
  },
};
</script>
<style lang="less" scoped>
.withdrawal {
  background: #f7f8fc;
  font-size: 0.37rem;
  height: 100vh;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  text-align: left;
}

.balance {
  color: #fff;
  overflow: hidden;
  border-radius: 5px;
}

.balance-info {
  background: linear-gradient(315deg, #a78e6f, #d5bd9d);
  border-radius: 8px;
  width: 8.93rem;
  margin: 0.43rem auto;
}

.current-balance {
  padding: 0.64rem 0 0.05rem 0.64rem;
  font-size: 0.35rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #fff;
}

.money {
  margin-left: 0.64rem;
  font-size: 0.85rem;
  font-family: DINPro-Medium, DINPro;
  font-weight: 500;
  color: #fff;
}

.withDrawMoney {
  display: flex;
  justify-content: space-between;
  margin: 0.21rem 0.64rem 0.37rem;
  align-items: center;
  background: hsla(0, 0%, 100%, 0);
  box-shadow: 0 0 0 0 #eeeff2;
  border-bottom: 1px solid #eeeff2;
}

.rate {
  padding: 0 0.64rem 0.37rem;
  opacity: 0.6;
}

.Single {}

.contant {
  padding: 0.67rem 0.53rem 0;
  background: #fff;
  border-radius: 5px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  overflow-y: auto;
}

.title {
  font-size: 0.4rem;
  font-weight: 400;
  color: #334360;
}

.input {
  display: flex;
  height: 1.6rem;
  align-items: center;
  border-bottom: 1px solid #eeeff2;
}

.leftTxt {
  font-size: 0.64rem;
  font-family: DINPro-Medium, DINPro;
  font-weight: 500;
  color: #334360;
}

.rightInput {
  font-size: 0.85rem;
  font-family: DINPro-Medium, DINPro;
  font-weight: 500;
  color: #334360;
  flex: 1;
  height: 100%;

  input {
    caret-color: #017aff;
    width: 100%;
    height: 100%;
  }
}

.tip {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #a7aab6;
  font-size: 0.32rem;
  font-weight: 400;
  margin: 0.27rem 0 0.4rem;
  font-size: 0.37rem;
}

.T-left-txt {}

.bank-info {
  margin-top: 0.37rem;
  padding: 0 0.53rem;
  background: #fff;
}

.bank-info .input {
  height: 1.07rem;
  line-height: 40px;
  font-size: 0.37rem;
  border-bottom: 1px solid #eee;
}

.bank-info .input.flex-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 0.27rem;
}

.flex-left-text {
  font-size: 0.35rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #23292f;
}

.card-number {
  text-shadow: 1px 1px 1px #eeeff2;
  line-height: 0.48rem;
  color: #000;
}

.withDraw .bank-num {
  text-shadow: 0 0 1px #aaa !important;
  font-weight: 700 !important;
}

.notice {
  color: #666;
  padding: 0.4rem 0.53rem;
  font-size: 0.35rem;
}

.notice .Ntitle {
  color: #262c32;
}

.notice .Ncontant {
  margin-top: 0.16rem;
  font-size: 13px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #a7aab6;
}

.submit {
  line-height: 1.07rem;
  text-align: center;
  color: #fff;
  margin: 0.4rem 0.53rem;
  font-size: 14px;
  background: linear-gradient(90deg, #6dcab8, #3a78cb);
}

.ShowInvitationDiv {
  padding: 0.13rem 0.4rem 0.4rem 0.4rem;
  text-align: center;
}

.digital-keyboard-modal.digital-keyboard-modal-1,
.digital-keyboard-modal.digital-keyboard-modal-4 {
  height: auto;
  background: #fff url(../assets/image/input.png) top/100% auto no-repeat;
}

.dk-title {
  font-size: 0.38rem;
  font-weight: 800;
  color: #fff;
  line-height: 1.2rem;
  text-align: center;
}

.dk-subtitle {
  font-size: 0.32rem;
  color: #989894;
  line-height: 3;
  text-align: center;
}

.pwd-box {
  padding-left: 0.13rem;
  position: relative;
  text-align: center;
}

.pwd-forget {
  font-size: 0.32rem;
  color: #26c6b3;
  padding: 0.27rem 0 0.4rem;
  text-align: center;
  line-height: 0.43rem;
}

.pwd-text.active:after {
  animation: twinkle-data-v-0b1f9cd8 1s infinite;
  height: 70%;
  width: 1px;
  content: "";
  position: absolute;
  top: 15%;
  left: 50%;
  margin-left: -1px;
  background-color: #4fa5e1;
}
</style>